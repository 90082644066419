import {useCallback, useEffect} from 'react'
import {
  createGoogleAuthUrl,
  googleSheetScope,
  googleWindowName,
  googleWindowSpecs,
} from '../helpers'

export const useClientGoogleAuth = (
  handleReturnMessage: (event: {
    origin: string
    data: {payload: {code: string; scope: string}; source: string}
  }) => Promise<void>,
  appId: string
) => {
  const handleGoogleLogin = useCallback(
    (platform: `GoogleSheet`, apiBaseUrl: string) => {
      const origin = window.location.origin
      if (platform && appId) {
        const authUrl = {
          GoogleSheet: createGoogleAuthUrl({
            scope: googleSheetScope,
            appId,
            origin,
            apiBaseUrl,
            platform: `GoogleSheet`,
          }),
        }

        const newWindow = window.open(
          authUrl[platform],
          googleWindowName,
          googleWindowSpecs()
        )
        if (newWindow) {
          newWindow.focus()
        } else {
          // Handle the error if window.open fails
          console.error(
            `Unable to open the authentication window. Please ensure that popups are enabled in your browser settings.`
          )
        }
      }
    },
    [appId]
  )

  useEffect(() => {
    if (appId) window.addEventListener(`message`, handleReturnMessage)
    return () => {
      if (appId) window.removeEventListener(`message`, handleReturnMessage)
    }
  }, [handleReturnMessage, appId])

  return {handleGoogleLogin}
}
