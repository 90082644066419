import {PlusSmallIcon} from '@heroicons/react/24/outline'
import {Card, Text} from '@myadbox/gatsby-theme-nebula'
import {Integration} from '@myadbox/nebula-service-api'
import {AppLogo, Badge, Button} from '@myadbox/stellar-ui'
import {Link, useTranslation} from 'gatsby-plugin-react-i18next'
import {badgeNameTranslation, renderLogo} from '../helpers'

export interface IntegrationCardProps {
  integration: Integration
  enabled: boolean
  showAsDefault?: boolean
  buttonModal?: React.ReactNode | null
}

export const IntegrationCard = ({
  integration,
  enabled,
  showAsDefault,
  buttonModal,
}: IntegrationCardProps) => {
  const {
    t,
    i18n: {language},
  } = useTranslation()

  const {id, category, name, logo, intro = null} = integration

  const nonDefaultBtnText = enabled
    ? t(`settings.integrations.viewSettings`)
    : t(`settings.integrations.connect`)

  return (
    <Card key={id} className="p-4">
      {category ? (
        <div>
          <Badge>
            {(integration.category &&
              t(`${badgeNameTranslation[integration.category]}`)) ||
              `Unknown`}
          </Badge>
        </div>
      ) : null}
      <div className="flex h-full flex-col p-4">
        {logo && (
          <div className="my-4 grid max-h-11 grid-cols-3 items-center gap-4 px-8 sm:px-4 xl:px-16 2xl:max-h-14 2xl:px-24">
            <div className="text-ui-700 text-center dark:text-[--brand-color]">
              <AppLogo className="inline-block" />
            </div>
            <div className="px-4 text-center">
              <PlusSmallIcon className="inline-block" />
            </div>
            {renderLogo(integration)}
          </div>
        )}
        <div className="text-center">
          <Text variant="heading">
            {t(`settings.integrations.sesimiToName`, {
              name,
            })}
          </Text>
          <div className="my-4">
            <Text variant="bodySmall">
              {intro?.[language] || intro?.[`en-AU`]}
            </Text>
          </div>
        </div>
        <div className="mt-auto self-center">
          {!buttonModal ? (
            <Button
              aria-label="View Settings"
              variant={showAsDefault || enabled ? `secondary` : `primary`}
              as={Link}
              to={`/settings/integrations/${id}`}
            >
              {showAsDefault
                ? t(`settings.integrations.viewDefault`)
                : nonDefaultBtnText}
            </Button>
          ) : (
            buttonModal
          )}
        </div>
      </div>
    </Card>
  )
}
