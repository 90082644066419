import {Dropdown} from 'packages/plugin/gatsby-theme-nebula'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {ReactNode} from 'react'
import {ActiveIntegrationType} from '@myadbox/nebula-service-api'

const SocialsIntegrationsTrigger = ({
  text,
  key,
  disabled = false,
  renderIcon,
  handleSelect,
}: {
  text: string
  key: ActiveIntegrationType
  disabled?: boolean
  renderIcon?: ReactNode
  handleSelect: () => void
}) => {
  return (
    <Dropdown.Item
      key={key as string}
      onSelect={handleSelect}
      disabled={disabled}
    >
      <div className="flex gap-2">
        {renderIcon}
        {text}
      </div>
    </Dropdown.Item>
  )
}

export default withTranslationProvider(SocialsIntegrationsTrigger)
