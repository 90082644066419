type Platform = `GoogleSheet` | `GoogleAds`

export const paramsToObject = (query: string): {[key: string]: string} => {
  const regex = /^[?&]?([\w-]+=[^&]*)?(?:&[\w-]+=[^&]*)*$/
  const isValid = regex.test(query)
  if (!isValid) return {}
  try {
    const params = new URLSearchParams(query)
    const obj: {[key: string]: string} = {}
    for (const [key, value] of params) {
      obj[key] = value
    }
    return obj
  } catch (error) {
    return {}
  }
}

export const redirectSource = `google-auth`

// TODO: Make translation dynamic
export const googleRedirectUri = (origin: string) =>
  `${origin}/en-US/settings/integrations/`

export const googleAdsRedirectUri = (origin: string) =>
  `${origin}/en-US/assets/`

export const redirectUri = (apiBaseUrl: string) =>
  encodeURIComponent(`${apiBaseUrl}/googleIntegrations/googleSheetRedirect`)

export const adsRedirectUri = (apiBaseUrl: string) =>
  encodeURIComponent(`${apiBaseUrl}/socialIntegrations/googleRedirect`)

export const createGoogleAuthUrl = ({
  scope,
  appId,
  origin,
  apiBaseUrl,
  platform,
}: {
  scope: string
  appId: string
  origin: string
  apiBaseUrl: string
  platform: Platform
}) =>
  [
    `https://accounts.google.com/o/oauth2/v2/auth`,
    `?client_id=${appId}`,
    `&redirect_uri=${
      platform === `GoogleSheet`
        ? redirectUri(apiBaseUrl)
        : adsRedirectUri(apiBaseUrl)
    }`,
    `&state=${
      platform === `GoogleSheet`
        ? encodeURIComponent(googleRedirectUri(origin))
        : encodeURIComponent(googleAdsRedirectUri(origin))
    }`,
    `&scope=${scope}`,
    `&response_type=code`,
    `&prompt=consent`,
    `&access_type=offline`,
  ].join(``)

export const googleSheetScope = `https://www.googleapis.com/auth/spreadsheets.readonly`
export const googleAdsScope = `https://www.googleapis.com/auth/adwords`
export const googlePeoplesScope = `https://www.googleapis.com/auth/userinfo.profile`

export const googleWindowSpecs = () => {
  const width = 600
  const height = 650
  // Automatic centering of Window
  const x = window.outerWidth / 2 + window.screenX - width / 2
  const y = window.outerHeight / 2 + window.screenY - height / 2
  const specs = [
    `toolbar=no, location=no, directories=no, status=no, menubar=no, `,
    `menubar=no, scrollbars=no, resizable=no, copyhistory=no, `,
    `width=${width}, `,
    `height=${height}, `,
    `top=${y}, `,
    `left=${x}`,
  ].join(``)
  return specs
}

export const googleWindowName = `Sesimi Integration Authorization`
