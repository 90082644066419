import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {UPDATE_BILLING} from '../../mutations'
import {FETCH_ACCOUNT_BILLING} from '../../queries'
import {UseAccountBillingOutput} from '../../types'

export const useAccountBilling = (): UseAccountBillingOutput => {
  const [lazyFetchAccountBilling, fetchAccountBillingResult] = useLazyQuery(
    FETCH_ACCOUNT_BILLING
  )

  const fetchAccountBilling = useCallback(() => {
    lazyFetchAccountBilling()
  }, [lazyFetchAccountBilling])

  const [doUpdateBilling, updateBillingResponse] = useMutation(UPDATE_BILLING)
  const updateBilling = useCallback(
    billingInput => {
      return doUpdateBilling({
        variables: {billingInput: {...billingInput, __typename: undefined}},
      })
    },
    [doUpdateBilling]
  )

  return {
    fetchAccountBilling,
    fetchAccountBillingResult,
    updateBilling,
    updateBillingResponse,
  }
}
