import {CheckCircleIcon} from '@heroicons/react/20/solid'
import {CheckIcon} from '@heroicons/react/24/outline'
import {Modal, RadioButton, TextArea} from '@myadbox/gatsby-theme-nebula'
import {
  AppLink,
  Asset,
  AssetLinkage,
  ExportSocialInput,
} from '@myadbox/nebula-service-api'
import {ActionGroup, Button, FormItem, Text} from '@myadbox/stellar-ui'
import {Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {default as withTranslationProvider} from '../../../locales/withTranslationProvider'
import {toTitleCase} from '../../../utils/helpers'
import {useSocialsIntegrations, useSocialsModalStore} from '../hooks'
import {SocialsIntegrationFormValues} from '../types'

const SocialsIntegrationsForm = ({assets}: {assets: Asset[]}) => {
  const {t} = useTranslation(`socialsIntegrations`)
  const integrationType = useSocialsModalStore(state => state.integrationType)
  const reset = useSocialsModalStore(state => state.reset)
  const {connectedIntegration, exportSocial, exportResponse} =
    useSocialsIntegrations()
  const {data, loading, error} = exportResponse
  const [pageId, setPageId] = useState<string>(``)

  const isGoogleAds = integrationType === `GOOGLE_ADS`

  const versions = assets[0].versions
  const activeCloudinaryId =
    versions.length === 1
      ? versions[0].cloudinaryId
      : (versions.find(version => version.active)?.cloudinaryId as string)

  const linkageApps = assets[0]?.linkages?.find(
    (linkage: AssetLinkage) => linkage.type === integrationType
  )?.apps as AppLink[]

  const inLinkages = (id: string) =>
    linkageApps?.some(
      (app: AppLink) =>
        app.cloudinaryId === activeCloudinaryId && app.appId === id
    )

  const initialValues: SocialsIntegrationFormValues = {
    page: connectedIntegration?.apps[0]?.id ?? null,
    asset: assets[0],
    message: ``,
    user: connectedIntegration?.user?.name ?? `Sesimi User`,
  }

  useEffect(() => {
    if (pageId === ``) setPageId(connectedIntegration?.apps[0].id as string)
  }, [connectedIntegration, pageId])

  useEffect(() => {
    if (data?.createImagePost && !error) {
      setTimeout(reset, 1500)
      window.location.reload()
    }
  }, [data, error, reset])

  const handleSubmit = async (values: SocialsIntegrationFormValues) => {
    const {asset, message, page} = values
    const payloadBody = {
      id: page,
      integrationId: connectedIntegration?.integrationId,
      assetId: asset?.id,
      type: String(integrationType),
    }
    const payload = isGoogleAds
      ? ({
          ...payloadBody,
        } as ExportSocialInput)
      : ({
          ...payloadBody,
          message,
        } as ExportSocialInput)
    exportSocial(payload)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({values, setFieldValue, isValid}) => (
        <Form className="flex flex-col">
          <Modal.Body>
            <div className="flex flex-col gap-4">
              <div>
                <Text variant="bodySmall" color="textSecondary">
                  {t(`form.loginAs`)}
                </Text>
                <Text>{values?.user}</Text>
              </div>
              <FormItem
                label={
                  <FormItem.Label>
                    {t(`form.pages.${isGoogleAds}`)}
                  </FormItem.Label>
                }
              >
                <div>
                  {connectedIntegration?.apps.map(app => (
                    <label
                      key={app.id}
                      className="flex flex-row items-center gap-2 py-1"
                    >
                      <RadioButton
                        name="page"
                        id={app.id}
                        value={app.name}
                        checked={values.page === app.id}
                        disabled={inLinkages(app.id)}
                        onChange={() => {
                          setFieldValue(`page`, app.id)
                          setPageId(app.id)
                        }}
                        required
                      />
                      <Text>
                        {app.name}
                        {isGoogleAds && ` (${app.id})`}
                      </Text>
                      {inLinkages(app.id) && (
                        <CheckCircleIcon width={20} height={20} />
                      )}
                    </label>
                  ))}
                </div>
              </FormItem>
              {isGoogleAds ? (
                <div />
              ) : (
                <TextArea.Formik
                  placeholder={`Message`}
                  id="message"
                  name="message"
                  label={t(`form.enterMessage.${isGoogleAds}`)}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex flex-col gap-4">
              {inLinkages(pageId) && <Text>{t(`form.inLinkages`)}</Text>}
              {error && (
                <div className="mb-2 text-red-600">
                  <Text color="inherit">{error.message}</Text>
                </div>
              )}
              <ActionGroup>
                {!loading && data?.createImagePost ? (
                  <div
                    className={`
                    flex
                    items-center
                    justify-center
                    gap-1
                    text-green-600
                  `}
                  >
                    <CheckIcon width={24} height={24} />
                    <Text>{data.createImagePost.message}</Text>
                  </div>
                ) : (
                  <>
                    <Button variant="secondary" type="button" onClick={reset}>
                      {t(`form.cancel`)}
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      id={`socials-integrations-submit`}
                      disabled={!isValid || loading || inLinkages(pageId)}
                      loading={loading}
                      loadingText={t`form.updating`}
                    >
                      {t(`form.share.${isGoogleAds}`, {
                        integration: toTitleCase(String(integrationType)),
                      })}
                    </Button>
                  </>
                )}
              </ActionGroup>
            </div>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default withTranslationProvider(SocialsIntegrationsForm)
