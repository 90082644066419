import {createContext} from 'react'
import {PluginOptions, usePluginOptionsQuery} from './usePluginOptions'

export const StateContext = createContext<PluginOptions>(null)
StateContext.displayName = `PluginOptions StateContext`

export const PluginOptionsProvider = ({children}): JSX.Element => {
  // useRef so this persists across rerenders = better performance
  const pluginOptions = usePluginOptionsQuery()

  return (
    <StateContext.Provider value={pluginOptions}>
      {children}
    </StateContext.Provider>
  )
}
