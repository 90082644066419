import type {ExternalLinkType, InternalLinkType} from '@myadbox/nebula-layouts'
import {LayoutApp, LayoutAppMenu} from '@myadbox/nebula-layouts'
import {getJwt, useAccount} from '@myadbox/nebula-service-api'
import {useLocation} from '@reach/router'
import {Link} from 'gatsby-plugin-react-i18next'
import {I18nextProvider, useTranslation} from 'react-i18next'
import useCognitoAuthToken from '../../hooks/useCognitoAuthToken'
import nebulai18n from '../../locales/i18n'
import IndexHeader from '../Headers/IndexHeader'
import LinkedAppLogo from '../LinkedAppLogo'
import {getCoreAppUrl, getTitanAppUrl} from './helpers'
import useFeatureMenuItems from './useFeatureMenuItems'

const TheLayout = ({children, Header = <IndexHeader />, ...props}) => {
  return (
    <LayoutApp
      Logo={<LinkedAppLogo />}
      MobileMenu={
        <I18nextProvider i18n={nebulai18n}>
          <LocalisedMenu mobile />
        </I18nextProvider>
      }
      DesktopMenu={
        <I18nextProvider i18n={nebulai18n}>
          <LocalisedMenu />
        </I18nextProvider>
      }
      Header={Header}
      {...props}
    >
      {children}
    </LayoutApp>
  )
}

const LocalisedMenu = ({mobile = false}) => {
  const location = useLocation()
  const {
    t,
    i18n: {language},
  } = useTranslation(`layout`)
  const {account} = useAccount()
  const {hostname, protocol} = useLocation()

  const token = useCognitoAuthToken()
  const jwt = getJwt()
  const {shownFeatures} = useFeatureMenuItems()
  const coreUrl = account?.configuration?.coreUrl

  const allActiveProducts = [`home`, `assets`, ...shownFeatures]

  const menuItems = [
    {
      label: t`home`,
      feature: `home`,
      to: `/dashboard/`,
      featureName: `home`,
      internal: true,
    },
    {
      label: t`assets`,
      feature: `assets`,
      to: `/assets/`,
      featureName: `assets`,
      internal: true,
    },
    {
      label: t`reports`,
      feature: `reports`,
      to: `/reports/`,
      featureName: `reports`,
      internal: true,
    },
    {
      label: t`funds`,
      feature: `funds`,
      to: getCoreAppUrl(coreUrl, `myfunds`, token, language),
      featureName: `funds`,
      internal: false,
    },
    {
      label: t`planner`,
      feature: `planner`,
      to: getCoreAppUrl(coreUrl, `myplanner`, token, language),
      featureName: `planner`,
      internal: false,
    },
    {
      label: t`awards`,
      feature: `awards`,
      to: getCoreAppUrl(coreUrl, `myawards`, token, language),
      featureName: `awards`,
      internal: false,
    },
    {
      label: t`workflows`,
      feature: `workflows`,
      to: getCoreAppUrl(coreUrl, `workflows`, token, language),
      featureName: `workflows`,
      internal: false,
    },
    {
      label: t`brand`,
      feature: `brand`,
      to: getTitanAppUrl(protocol, hostname, jwt, language, `/brand`),
      featureName: `brandTitan`,
      internal: false,
    },
    {
      label: t`planner`,
      feature: `planner`,
      to: getTitanAppUrl(protocol, hostname, jwt, language, `/planner`),
      featureName: `plannerTitan`,
      internal: false,
    },
  ]

  const internalLinks: InternalLinkType[] = menuItems
    .map(item => {
      if (allActiveProducts.includes(item.featureName) && item.internal) {
        return {
          to: item.to,
          label: item.label,
          feature: item.feature as InternalLinkType[`feature`],
          as: Link,
          isActive: Boolean(location.pathname.includes(item.to)),
        }
      }
      return null // Return null for inactive products
    })
    .filter(link => link !== null) // Filter out null values

  const externalLinks: ExternalLinkType[] = menuItems
    .map(item => {
      if (allActiveProducts.includes(item.featureName) && !item.internal) {
        return {
          href: item.to,
          label: item.label,
          feature: item.feature as InternalLinkType[`feature`],
          rel: `noopener noreferrer`,
          target: `_self` as ExternalLinkType[`target`],
        }
      }
      return null
    })
    .filter(link => link !== null)

  return (
    <LayoutAppMenu
      internalLinks={internalLinks}
      externalLinks={externalLinks}
      mobile={mobile}
    />
  )
}

export default TheLayout
