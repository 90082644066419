import {useLazyQuery, useMutation} from '@apollo/client'
import {useCallback} from 'react'
import {UPDATE_ACCOUNT_INTEGRATION} from '../../mutations'
import {FETCH_ACCOUNT_INTEGRATIONS} from '../../queries'
import {Account, UseAccountIntegrationsOutput} from '../../types'

export const useAccountIntegrations = (
  accountName: Account[`accountName`]
): UseAccountIntegrationsOutput => {
  const [lazyFetchAccountIntegrations, fetchAccountIntegrationsResults] =
    useLazyQuery(FETCH_ACCOUNT_INTEGRATIONS, {fetchPolicy: `cache-and-network`})

  const fetchAccountIntegrations = useCallback(
    (accountName: string) => {
      return lazyFetchAccountIntegrations({variables: {accountName}})
    },
    [lazyFetchAccountIntegrations]
  )

  const [doUpdateAccountIntegration, updateAccountIntegrationResponse] =
    useMutation(UPDATE_ACCOUNT_INTEGRATION)
  const updateAccountIntegration = useCallback(
    accountIntegrationInput => {
      return doUpdateAccountIntegration({
        variables: {accountIntegrationInput},
        refetchQueries: [
          {
            query: FETCH_ACCOUNT_INTEGRATIONS,
            variables: {accountName},
          },
        ],
      })
    },
    [doUpdateAccountIntegration, accountName]
  )

  return {
    fetchAccountIntegrations,
    fetchAccountIntegrationsResults,
    updateAccountIntegration,
    updateAccountIntegrationResponse,
  }
}
