import {useCallback, useEffect} from 'react'
import {
  createMetaAuthUrl,
  facebookScopes,
  instagramScopes,
  metaWindowName,
  metaWindowSpecs,
} from '../utils/helpers'
import {
  createGoogleAuthUrl,
  googleAdsScope,
  googlePeoplesScope,
} from '../lib/GoogleSheetsAuthModal/helpers'
import {ActiveIntegrationType} from 'packages/data/nebula-service-api'

export const useClientMetaAuth = (
  handleReturnMessage: (event: {
    origin: string
    data: {
      payload: string
      source: string
      platform: string
    }
  }) => unknown,
  appId: string
) => {
  const handleMetaLogin = useCallback(
    (platform: ActiveIntegrationType, apiBaseUrl: string) => {
      const origin = window.location.origin
      if (platform && appId) {
        const authUrl = {
          INSTAGRAM: createMetaAuthUrl({
            scope: instagramScopes,
            appId,
            origin,
            apiBaseUrl,
          }),
          FACEBOOK: createMetaAuthUrl({
            scope: facebookScopes,
            appId,
            origin,
            apiBaseUrl,
          }),
          GOOGLE_ADS: createGoogleAuthUrl({
            scope: `${googleAdsScope}+${googlePeoplesScope}`,
            appId,
            origin,
            apiBaseUrl,
            platform: `GoogleAds`,
          }),
          EMBED: ``,
        }
        const newWindow = window.open(
          authUrl[platform],
          metaWindowName,
          metaWindowSpecs()
        )
        if (newWindow) {
          newWindow.focus()
        } else {
          // Handle the error if window.open fails
          console.error(
            `Unable to open the authentication window. Please ensure that popups are enabled in your browser settings.`
          )
        }
      }
    },
    [appId]
  )

  useEffect(() => {
    if (appId) window.addEventListener(`message`, handleReturnMessage)
    return () => {
      if (appId) window.removeEventListener(`message`, handleReturnMessage)
    }
  }, [handleReturnMessage, appId])

  return {handleMetaLogin}
}
