import {XMarkIcon} from '@heroicons/react/20/solid'
import {ComponentProps, Fragment, useEffect} from 'react'
import {Transition} from 'react-transition-group'
import {cn} from '../../util'
import type {HeadlessProps} from '../../util/types'
import {Text} from '../Text'

interface ModalProps extends HeadlessProps<`div`, (typeof Modal)[`classes`]> {
  open?: boolean
  onClose?: () => void
  onExit?: () => void
}

export const MODAL_ROOT_IDENT = `modal-root`

export default function Modal({open, onExit, ...props}: Readonly<ModalProps>) {
  const Wrapper = props.components?.wrapper ?? Fragment

  return (
    <Transition timeout={200} in={open} onExit={onExit}>
      {state =>
        open && (
          <Wrapper>
            <ModalBase
              {...props}
              classNames={{
                ...props.classNames,
                overlay: cn(
                  state === `entered` ? `opacity-1` : `opacity-0`,
                  props.classNames?.overlay
                ),
              }}
            />
          </Wrapper>
        )
      }
    </Transition>
  )
}

function ModalBase(props: Readonly<Omit<ModalProps, `open` | `onExit`>>) {
  const {className, classNames, children, onClose, ...restProps} = props

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === `Escape`) onClose?.()
    }
    document.addEventListener(`keydown`, handleEscape)

    return () => document.removeEventListener(`keydown`, handleEscape)
  }, [onClose])

  return (
    <div className={cn(Modal.classes.overlay, classNames?.overlay)}>
      <div
        {...restProps}
        className={cn(Modal.classes.root, classNames?.root, className)}
      >
        <div className={cn(Modal.classes.container, classNames?.container)}>
          <button
            className={cn(
              Modal.classes[`close-trigger`],
              classNames?.[`close-trigger`]
            )}
            onClick={onClose}
          >
            <XMarkIcon width="18" height="18" />
          </button>
          {children}
        </div>
      </div>
    </div>
  )
}
Modal.classes = {
  wrapper: ``,
  overlay: [
    `fixed inset-0 z-50 w-full h-full`,
    `flex items-center justify-center overflow-x-hidden overflow-y-auto`,
    `bg-[--cloudinary-overlay] cursor-pointer duration-400`,
  ],
  root: [`max-w-full inline-block`, `cursor-default focus:outline-none`],
  container: [
    `relative w-screen m-auto overflow-y-auto`,
    `max-h-screen max-w-sm`,
    `rounded-500 bg-ui-100 text-ui-600 dark:text-ui-500`,
  ],
  'close-trigger': [
    `absolute right-0 top-0 z-10 mr-4 mt-5 p-1.5`,
    `rounded-full text-ui-500 hocus:text-ui-900 hocus:bg-ui-200`,
  ],
} as const

export interface ModalHeaderProps
  extends HeadlessProps<`div`, (typeof ModalHeader)[`classes`]> {
  contentProps?: ComponentProps<typeof Text>
}

export function ModalHeader(props: Readonly<ModalHeaderProps>) {
  const {classNames, className, contentProps, children, ...restProps} = props
  return (
    <div
      className={cn(ModalHeader.classes.root, classNames?.root, className)}
      {...restProps}
    >
      <Text
        tag="h2"
        variant="headingLarge"
        {...contentProps}
        className={cn(
          ModalHeader.classes.content,
          classNames?.content,
          contentProps?.className
        )}
      >
        {children}
      </Text>
    </div>
  )
}
ModalHeader.classes = {
  root: [`py-6 pl-6 pr-12`, `border-b border-b-ui-200 dark:border-b-ui-50`],
  content: ``,
} as const

Modal.Header = ModalHeader

export type ModalContentProps = HeadlessProps<
  `div`,
  (typeof ModalContent)[`classes`]
>

export function ModalContent({
  classNames,
  ...props
}: Readonly<ModalContentProps>) {
  return (
    <div
      {...props}
      className={cn(
        ModalContent.classes.root,
        classNames?.root,
        props.className
      )}
    />
  )
}
ModalContent.classes = {
  root: [`p-6`],
} as const

Modal.Content = ModalContent

export interface ModalFooterProps
  extends HeadlessProps<`footer`, (typeof ModalFooter)[`classes`]> {
  reversed?: boolean
}

export function ModalFooter({
  classNames,
  reversed,
  ...props
}: Readonly<ModalFooterProps>) {
  const overrideClasses = cn(
    ModalFooter.classes.root,
    classNames?.root,
    props.className
  )
  return (
    <footer
      {...props}
      className={cn(overrideClasses, {
        'flex-row-reverse': reversed,
        'space-x-reverse': reversed && overrideClasses.includes(`space-x-`),
        'space-y-reverse': reversed && overrideClasses.includes(`space-y-`),
      })}
    />
  )
}
ModalFooter.classes = {
  root: [`px-6 pb-6 flex space-x-2`],
} as const

Modal.Footer = ModalFooter
