import {FacebookIcon, GoogleAdsIcon, InstagramIcon} from '@myadbox/stellar-ui'
import withTranslationProvider from '../../../locales/withTranslationProvider'
import {ReactNode} from 'react'
import {useSocialsModalStore} from '../hooks'
import SocialsIntegrationsTrigger from '../SocialsIntegrationsTrigger'
import {useTranslation} from 'react-i18next'
import {useFeatureFlags} from '@myadbox/gatsby-theme-nebula/hooks'
import {CodeBracketIcon} from '@heroicons/react/20/solid'
import {ActiveIntegrationType} from '@myadbox/nebula-service-api'
import {toPascalCase} from '../../../utils/helpers'

interface IntegrationItem {
  type: ActiveIntegrationType
  icon: ReactNode
}

const SocialsIntegrationsList = () => {
  const {t} = useTranslation(`socialsIntegrations`)
  const {
    featureFlags: {showGoogleAdsConnection, showEmbedCopy},
  } = useFeatureFlags()

  const integrationList: IntegrationItem[] = [
    {
      type: `FACEBOOK`,
      icon: <FacebookIcon size={20} />,
    },
    {
      type: `INSTAGRAM`,
      icon: <InstagramIcon size={20} />,
    },
    {
      type: `GOOGLE_ADS`,
      icon: <GoogleAdsIcon size={20} />,
    },
    {
      type: `EMBED`,
      icon: (
        <div className="size-5">
          <CodeBracketIcon />
        </div>
      ),
    },
  ].filter(({type}) => {
    if (!showEmbedCopy) return type !== `EMBED`
    if (!showGoogleAdsConnection) return type !== `GOOGLE_ADS`
    return true
  }) as IntegrationItem[]

  const setModalOpen = useSocialsModalStore(state => state.setModalOpen)
  const setIntegrationType = useSocialsModalStore(
    state => state.setIntegrationType
  )

  return (
    <>
      {integrationList.map(({type, icon}) => (
        <SocialsIntegrationsTrigger
          key={type}
          text={t(`list.${toPascalCase(type)}`)}
          renderIcon={icon}
          handleSelect={() => {
            setModalOpen()
            setIntegrationType(type)
          }}
        />
      ))}
    </>
  )
}

export default withTranslationProvider(SocialsIntegrationsList)
