import * as enAuTrans from './en-AU'
import * as enUsTrans from './en-US'
import * as frCaTrans from './fr-CA'
import * as idIDTrans from './id-ID'
import * as koKRTrans from './ko-KR'
import * as ptBRTrans from './pt-BR'

export const transResources = {
  'en-AU': {...enAuTrans},
  'en-US': {...enUsTrans},
  'fr-CA': {...frCaTrans},
  'id-ID': {...idIDTrans},
  'ko-KR': {...koKRTrans},
  'pt-BR': {...ptBRTrans},
}
