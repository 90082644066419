import {useAccount} from '@myadbox/nebula-service-api'
import {useEffect, useState} from 'react'
import useUserSession from '../../hooks/useUserSession'

export const featureItems = [
  `reports`,
  `funds`,
  `workflows`,
  `brand`,
  `planner`,
  `awards`,
  `approvals`,
  `brandTitan`,
  `plannerTitan`,
] as const

type FeatureItem = (typeof featureItems)[number]

const useFeatureMenuItems = () => {
  const [shownFeatures, setShownFeatures] = useState<FeatureItem[]>([])

  const {account} = useAccount()
  const {isAccountAdmin} = useUserSession()

  const showFunds = Boolean(account?.products?.funds)
  const showPlanner = Boolean(account?.products?.planner)
  const showWorkflows = Boolean(account?.products?.workflows)
  const showAwards = Boolean(account?.products?.awards)
  const showApprovals = Boolean(account?.products?.approvals)
  const showBrand = Boolean(
    isAccountAdmin && account?.configuration?.brandAssetId
  )
  const showReports = isAccountAdmin
  const showBrandTitan = Boolean(account?.products?.brandTitan)
  const showPlannerTitan = Boolean(account?.products?.plannerTitan)

  useEffect(() => {
    setShownFeatures(
      [
        showFunds && `funds`,
        showWorkflows && `workflows`,
        showBrand && `brand`,
        showReports && `reports`,
        showPlanner && `planner`,
        showAwards && `awards`,
        showApprovals && `approvals`,
        showBrandTitan && `brandTitan`,
        showPlannerTitan && `plannerTitan`,
      ].filter(Boolean) as FeatureItem[]
    )
  }, [
    showAwards,
    showFunds,
    showWorkflows,
    showBrand,
    showReports,
    showPlanner,
    showApprovals,
    showBrandTitan,
    showPlannerTitan,
  ])

  return {shownFeatures}
}

export default useFeatureMenuItems
