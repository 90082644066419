import {
  useConnectedIntegrations,
  useExportSocial,
  useIntegrations,
} from '@myadbox/nebula-service-api'
import {useEffect} from 'react'
import {useSocialsModalStore} from './useSocialsModalStore'
import {UseSocialsIntegrationsOutput} from '../types'
import {toTitleCase} from '../../../utils/helpers'

export const useSocialsIntegrations = (): UseSocialsIntegrationsOutput => {
  const integrationType = useSocialsModalStore(state => state.integrationType)
  const {fetchIntegrations, fetchIntegrationsResults} = useIntegrations()
  const {fetchConnectedIntegrations, fetchConnectedIntegrationsResults} =
    useConnectedIntegrations()
  const {exportSocial, exportResponse} = useExportSocial()

  useEffect(() => {
    fetchIntegrations()
    fetchConnectedIntegrations()
  }, [fetchConnectedIntegrations, fetchIntegrations])

  const {data: integrationData} = fetchIntegrationsResults
  const {data: connectedData, refetch} = fetchConnectedIntegrationsResults

  const {integrations} = integrationData ?? {}
  const {withIntegrations} = connectedData ?? {}

  const selectedIntegration = integrations?.find(
    integration => integration.name === toTitleCase(integrationType)
  )

  const connectedIntegration = withIntegrations?.find(
    conn => conn.integrationId === selectedIntegration?.id
  )

  return {
    integrations,
    refetch,
    withIntegrations,
    selectedIntegration,
    connectedIntegration,
    exportSocial,
    exportResponse,
  }
}
