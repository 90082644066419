import {Format, Quality, Unit} from '@myadbox/nebula-template-utils'
import {Account} from './src/accounts/types'
import {Asset, AssetType, cloudinaryFormats} from './src/assets/types'
import {Dataset} from './src/datasets/types'
import {User} from './src/profiles/types'
import {Template} from './src/templates/types'

/**
 * Blank objects for default placeholders
 * Note:
 * Nullify optional fields so that
 * they are handled properly in UI
 */

export const blankAccount: Account = {
  id: ``,
  name: ``,
  accountName: ``,
  configuration: {
    dashboardUrl: undefined,
    loginBackgroundUrl: undefined,
    logoUrl: undefined,
    coreUrl: undefined,
    loginConfig: {
      userPoolAppId: ``,
      userPoolId: ``,
      ssoEnabled: false,
      ssoConfig: undefined,
    },
  },
  products: undefined,
}

export const blankUser: User = {
  id: ``,
  userId: ``,
  givenName: ``,
  familyName: ``,
  fullName: ``,
  email: ``,
  avatar: undefined,
  language: ``,
  timezone: ``,
  sso: false,
  darkMode: `AUTO`,
  notificationPreferences: {
    templateShared: false,
  },
}

export const blankAsset: Asset = {
  id: ``,
  derivedFrom: undefined,
  type: AssetType.Image,
  name: ``,
  description: undefined,
  tags: [],
  categories: [],
  datasets: [],
  accesses: [],
  versions: [
    {
      active: false,
      cloudinaryId: ``,
      content: undefined,
      thumbnailId: undefined,
      previewId: undefined,
      resourceType: undefined,
      originalFilename: ``,
      originalFormat: cloudinaryFormats.Unknown,
      bytes: 1,
      width: undefined,
      height: undefined,
      addedById: ``,
      pages: undefined,
      createdAt: undefined,
    },
  ],
  stats: {views: 0, downloads: 0, events: []},
  approvers: undefined,
  createdBy: {
    userId: ``,
    fullName: ``,
    avatar: undefined,
  },
  createdAt: ``,
}

export const blankDataset: Dataset = {
  id: ``,
  data: {},
  nestedSchema: {
    id: ``,
    name: ``,
    title: ``,
    descendants: [
      {
        id: ``,
        title: ``,
        name: ``,
        type: `string`,
      },
    ],
  },
}

export const blankTemplate: Template = {
  id: undefined,
  type: undefined,
  publicId: undefined,
  secureUrl: undefined,
  thumbnailPublicId: undefined,
  templateUrl: ``,
  hostedUrl: undefined,
  configValues: {
    defaultSize: undefined,
    sizes: [
      {
        label: `Full page`,
        width: 990,
        height: 1361,
        format: Format.PDF,
        quality: Quality.High,
        unit: Unit.PX,
      },
    ],
    fields: undefined,
    features: undefined,
  },
  defaultValues: undefined,
}
