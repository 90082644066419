import {useLazyQuery} from '@apollo/client'
import {FETCH_ACCOUNTS} from '../../queries'
import type {Account, UseFetchAccountsOutput} from '../../types'

export const useFetchAccounts = (): UseFetchAccountsOutput => {
  const [fetchAccounts, fetchAccountsResults] = useLazyQuery<{
    accounts: Account[]
  }>(FETCH_ACCOUNTS)

  return {fetchAccounts, fetchAccountsResults}
}
