import {useQuery} from '@apollo/client'
import {FETCH_ACCOUNT} from '../../queries'
import type {Account} from '../../types'

export const useFetchAccount = (accountName: string) => {
  const {data} = useQuery<{account: Account}>(FETCH_ACCOUNT, {
    variables: {accountName},
    skip: !accountName,
  })

  return data?.account
}
