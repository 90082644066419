import defaultIntegrationsJson from './defaultIntegrations.json'
import googleIntegrationsJson from './googleIntegrations.json'
import googleSheetsAuthJson from './googleSheetIntegrations.json'
import socialActiveConnectionsJson from './socialactiveconnections.json'
import socialsIntegrationsJson from './socialsIntegrations.json'

export const {defaultIntegrations} = defaultIntegrationsJson
export const {socialActiveConnections} = socialActiveConnectionsJson
export const {googleIntegrations} = googleIntegrationsJson
export const {googleSheetIntegrations} = googleSheetsAuthJson
export const {socialsIntegrations} = socialsIntegrationsJson
