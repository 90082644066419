import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import enAULocale from 'date-fns/locale/en-AU'
import enLocale from 'date-fns/locale/en-US'
import frCALocale from 'date-fns/locale/fr-CA'
import idLocale from 'date-fns/locale/id'
import koLocale from 'date-fns/locale/ko'
import ptBRLocale from 'date-fns/locale/pt-BR'
import {
  type CSSProperties,
  type HTMLAttributes,
  type ReactNode,
  useState,
} from 'react'
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import {SupportedLocales, localeMap} from '../../util/dates'
import {noop} from '../../util/noop'
import {Button} from '../Button'
import {FormItem} from '../FormItem'
import {Text} from '../Text'
import {
  calendarClasses,
  calendarHeaderClasses,
  dayClassesActive,
  dayClassesDefault,
  inputClasses,
  inputDisabledClasses,
  inputEnabledClasses,
  monthClasses,
  popperClasses,
  timeClassesActive,
  timeClassesDefault,
} from './classes'
import './datepicker-overrides.css' // layout styling
import {getInitialStartDateTime, isSameDay, isSameTime} from './helpers'

export interface DatePickerProps
  extends Omit<HTMLAttributes<HTMLElement>, `onChange`> {
  startDateTime?: Date
  timeTitle?: string
  endDateTime?: Date
  showTime?: boolean
  locale?: SupportedLocales
  id: string
  disabled?: boolean
  name: string
  label: string
  labelHidden?: boolean
  endContent?: ReactNode
  onChange?: ({name, date}: {name: string; date: Date}) => void
}

const DatePicker = ({
  startDateTime,
  showTime = false,
  locale = `en-AU`,
  timeTitle = `Time`,
  onChange = noop,
  id,
  name,
  label,
  labelHidden,
  endContent,
  disabled,
  className,
}: DatePickerProps) => {
  const newDate = new Date()
  newDate.setHours(0, 0, 0, 0)
  const startDate =
    startDateTime &&
    getInitialStartDateTime(startDateTime, locale, newDate, showTime)
  const [start, setStart] = useState(startDate || newDate)

  const dateFormat = showTime ? `Pp` : `P`

  const onDateChange = (
    date: Date,
    onChange: {
      ({name, date}: {name: string; date: Date}): void
    }
  ) => {
    setStart(date)
    onChange({name, date})
  }

  return (
    <FormItem
      label={
        labelHidden ? null : (
          <FormItem.Label htmlFor={id}>{label}</FormItem.Label>
        )
      }
    >
      <div
        className={`relative`}
        style={
          {
            '--height-var-1': `14.5rem`,
            '--width-var-1': `1.7rem`,
          } as CSSProperties
        }
      >
        <ReactDatePicker
          selected={start}
          onChange={(date: Date) => onDateChange(date, onChange)}
          className={`
          ${inputClasses}
          ${className}
          ${disabled ? inputDisabledClasses : inputEnabledClasses}
        `}
          calendarClassName={calendarClasses}
          popperClassName={popperClasses}
          monthClassName={() => monthClasses}
          id={id}
          name={name}
          disabled={disabled}
          dayClassName={(date: Date) =>
            isSameDay(date, start) ? dayClassesActive : dayClassesDefault
          }
          timeClassName={(date: Date) =>
            isSameTime(date, start) ? timeClassesActive : timeClassesDefault
          }
          dateFormat={dateFormat}
          locale={localeMap[locale]}
          showTimeSelect={showTime}
          timeCaption={timeTitle}
          timeFormat="p"
          fixedHeight
          // timeIntervals={30} default
          renderCustomHeader={({
            monthDate,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div className={calendarHeaderClasses}>
              <Button
                slim
                variant="text"
                size="xs"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
              >
                <ChevronLeftIcon width={14} />
              </Button>
              <Text variant="caption">
                {monthDate.toLocaleString(locale, {
                  month: `long`,
                  year: `numeric`,
                })}
              </Text>
              <Button
                slim
                variant="text"
                size="xs"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
              >
                <ChevronRightIcon width={14} />
              </Button>
            </div>
          )}
        />
        <div
          className={`
            text-ui-500
            dark:text-ui-400
            pointer-events-none
            absolute
            right-0
            top-0
            mr-2
            flex
            h-full
            items-center
          `}
        >
          <ClockIcon width={20} />
        </div>
      </div>
      {endContent}
    </FormItem>
  )
}

const localeRegistryMapping = {
  'en-US': enLocale,
  'en-AU': enAULocale,
  'fr-CA': frCALocale,
  'ko-KR': koLocale,
  'id-ID': idLocale,
  'pt-BR': ptBRLocale,
} as const satisfies Record<SupportedLocales, Locale>

Object.entries(localeRegistryMapping).forEach(([key, locale]) => {
  registerLocale(localeMap[key as SupportedLocales], locale)
})

export default DatePicker
